import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Checklist = makeShortcode("Checklist");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <StatusBanner warning mdxType="StatusBanner">
      <p><strong parentName="p">{`This section is under construction. Join the `}<a parentName="strong" {...{
            "href": "https://chat.makerdao.com/channel/translation"
          }}>{`#translation`}</a>{` channel to learn more about translation opportunities.`}</strong></p>
    </StatusBanner>
    <h1>{`Editor Quick Start Guide`}</h1>
    <p>{`Welcome to the MakerDAO Translation Initiative. Our goal is to bring Maker’s educational content to the world by providing materials for people in their native languages. This document summarizes the guidelines for editors.`}</p>
    <h2>{`Roles and Responsibilities`}</h2>
    <hr></hr>
    <p>{`The editor’s role is to ensure that translated documents meet both our formatting and writing style standards.`}</p>
    <p>{`Responsibilities consist of proofreading documents for:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Spelling and Grammar:`}</strong>{` Correct mistakes and typos, if any are found.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Language:`}</strong>{` Ensure that the writing style is in-line with MakerDAO terminology and best practices. For example, some languages translate the word "blockchain" while others keep the English spelling.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Consistency:`}</strong>{` Ensure terms are translated uniformly across all documents.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Simplicity:`}</strong>{` Our aim is to reach a broad audience. Writing should be plain, simple, and accessible. Maintain contact with translators as they work on documents. Be sure to agree on translated terms and other conventions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Formatting:`}</strong>{` Ensure that translated documents retain original formatting.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Adherence to the `}<a parentName="strong" {...{
              "href": "/contribute/content/writing-style-guide/"
            }}>{`Style Guide`}</a>{`:`}</strong>{` Writing style must be consistent throughout all documents.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Hyperlinks:`}</strong>{` Give extra attention to this task, hyperlinks allow users to explore other MakerDAO resources. Ensure all links are functioning properly and included where necessary. Hyperlinks fall into three general categories:`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Links Between Translated Documents:`}</strong>{` See the `}<a parentName="p" {...{
            "href": "/contribute/content/writing-style-guide/"
          }}>{`links section`}</a>{` of the style-guide for guidance on handling relative links.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Links to Other MakerDAO Resources:`}</strong>{` These are to be left as-is unless there is a parallel translated resource in the same language.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Links to Other Pages:`}</strong>{` Try to source a native language version for the link. For example, if a link leads to a Wikipedia page, it’s likely that a version of this page exists in the native language.`}</p>
      </li>
    </ul>
    <h2>{`Best Practices`}</h2>
    <hr></hr>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Use Markdown:`}</strong>{` All documents are formatted in Markdown. They contain headers, lists, links, tables, etc. Information on Markdown can be found on `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/Markdown"
          }}>{`Wikipedia`}</a>{`. Alternatively, this `}<a parentName="p" {...{
            "href": "https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
          }}>{`cheat-sheet`}</a>{` is a good reference as well.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Maintain Original Formatting:`}</strong>{` Refer to the `}<a parentName="p" {...{
            "href": "/contribute/content/writing-style-guide/"
          }}>{`style guide`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Use a Markdown Editor:`}</strong>{` Editors like `}<a parentName="p" {...{
            "href": "https://hackmd.io/"
          }}>{`hackmd.io`}</a>{` or `}<a parentName="p" {...{
            "href": "https://code.visualstudio.com/"
          }}>{`VScode`}</a>{` make working with Markdown more convenient.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Editor Tools:`}</strong>{` Feel free to use any software that helps find translation errors and improve writing. We encourage using tools that assist workflow. Mindlessly copying from a program will disqualify you from receiving a bounty and from future assignments. Please use them as an aide and not a substitute for your work.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`Communicate Early and Often`}</strong>{`: Join the `}<a parentName="p" {...{
                "href": "https://chat.makerdao.com/channel/translation"
              }}>{`#translation`}</a>{` channel on our Chat. We encourage reaching out to translators and editors for guidance.`}</p>
          </li>
        </ul>
      </li>
    </ul>
    <h2>{`Getting Started`}</h2>
    <Checklist mdxType="Checklist">
      <p>{`Join our `}<a parentName="p" {...{
          "href": "https://chat.makerdao.com/"
        }}>{`Chat`}</a></p>
      <p>{`Ask about editing opportunities in the `}<a parentName="p" {...{
          "href": "https://chat.makerdao.com/channel/translation"
        }}>{`#translation`}</a>{` channel.`}</p>
      <p>{`Fill out this `}<a parentName="p" {...{
          "href": "https://airtable.com/shr415iT3e8S8nuzS"
        }}>{`application form`}</a></p>
      <p>{`Get assigned a task by the project coordinator`}</p>
      <p>{`Receive translated documents to be edited`}</p>
      <p>{`Go to the markdown editor of your choice`}</p>
      <p>{`Edit the document`}</p>
      <p>{`Forward the completed document to the project coordinator, keeping its original name, eg. `}<inlineCode parentName="p">{`dai.mdx`}</inlineCode>{`.`}</p>
      <p>{`Once the editing process is finished, the project coordinator will publish the work, and you will get information on how to collect the bounty.`}</p>
      <p>{`Fill out `}<a parentName="p" {...{
          "href": "https://airtable.com/shrPtfR6ddP2mbLM0"
        }}>{`feedback forms`}</a>{` to evaluate the translators`}</p>
      <p>{`Get paid`}</p>
    </Checklist>
    <h2>{`Need Assistance?`}</h2>
    <p>{`We've created a `}<a parentName="p" {...{
        "href": "https://chat.makerdao.com/channel/translation"
      }}>{`dedicated channel`}</a>{` for the Translation Initiative. Come talk to us.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`We're always looking for ways to improve this program. Please don't hesitate to share your feedback.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      